<template>
    <h3 style="color: red; margin-top: 5px;">Внимание!</h3>
    <p>Это ваши старые ключи! Это склад!</p>
    <p>Если ваш ключ устарел пожалуйста перейдите в этот раздель: <router-link to="/tester">ТыЫк</router-link></p>

<h4 
    style="text-align: center; font-family: 'vue-w25'; margin-top: 25px;"
    v-if="userKeys && loading">
        Загрузка...
</h4>
<div 
v-else
class="Regulator">
    <div
    class="Kart-Key" 
    :key="item.id" v-for="item in Dota">
        <img src="../assets/img/kart-key.png" width="150">
        <br>
        <textarea
        readonly
        v-model="item.KEY"
        :key="item.id"
        rows="9" 
        cols="52"/>
        <h4>{{ item.HWID }}</h4>
        <h3>Время: {{ item.Time }}</h3>
        <li v-if="item.ShowDate">До: {{ item.ShowDate }}</li>
        <li v-else>Год, Месяц , День</li>
        <span v-if="item.CAPS">Последний раз - <img src="../assets/img/keysAmount.png" width="30">{{ item.CAPS }}</span>
        <span v-else><img src="../assets/img/keysAmount.png" width="30">Not Found</span>
    </div>
    <div v-if="Dota ==! true">
        <h3>Упс, Что то ничего не найдено!</h3>
     </div>
    </div>
</template>
<script setup>
import notFound from '../pages/NotFound.vue'
import { ref, onBeforeMount } from 'vue'
import axios from 'axios'
import configs from '../configs/config.js'

    const userKeys = window.localStorage.getItem('identification')
    const userKeysA = userKeys
    const Dota = ref(null)
    const loading = ref(true)

    async function getHwid() {
        try {
        loading.value = true;
        const response = await axios.get(`${configs.BackJSON}/Dota?IDFSITE=${userKeysA}`);
        Dota.value = response.data
        } catch (err) {
        console.log(err)
        } finally {
            setTimeout(() => {
                loading.value = false;
            }, 1000);
        }
    }

    onBeforeMount(() => {
        getHwid()
    })

</script>

<style scoped>
textarea{
    font-size: 6px; 
    text-align: center; 
    font-family: 'vue-w25'; 
    border-radius: 5px;
    width: 90%;
    height: auto;
}
.Regulator h3, h4{
    color: black;
    font-family: fantasy;
    font-size: 15px;
}
li{
 color: black;
 font-family: 'vue-w25';
}
.Regulator{
    border-radius: 5px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap-reverse;
    padding-top: 5%;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 25px;
    padding-top: 5px;
}
.Kart-Key{
    width: 29%;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .1);
    padding: 5px;
    background-color: rgb(223, 236, 248);
    margin-bottom: 15px;
}
.Kart-Key img{
    height: auto;
}
@media(max-width:480px){
    .Regulator{
        width: 100%;
        margin-top: 30%;
        padding-top: 0;
    }
    .Kart-Key{
        width: 90%;
    }
    textarea{
        font-size: 6.5px;
        width: 90%;
        height: 15vh;
    }
    h4, li{
        font-size: medium;
        overflow: hidden;
    }
}
</style>