<script setup>
import axios from 'axios';
import { ref } from 'vue';
import configs from '@/configs/config';

//Добавить призы
const IMYA = ref('')
const Telegram = ref('')
const Buyed = ref('')
const Whoadd = ref('')

const onSubmit = async () => {
    await axios.post(`${configs.BackJSON}/prizedota`, {
        Name: IMYA.value,
        Telegram: Telegram.value,
        BuyedHack: Buyed.value,
        Whoadd: Whoadd.value
    })
    .then(() => {
        alert('Готово')
        location.reload()
    })
    .catch(() => {
        alert('Что то не так.')
    })
}
//End Призы

const Prize = ref('')
const getPrizeUsers = async () => {
    await axios.get(`${configs.BackJSON}/prizedota`)
    .then(res => {
        Prize.value = res.data
    })
}

const DeleteUser = async (user) => {
    if(confirm('Удалить Юзера?')){
        await axios.delete(`${configs.BackJSON}/prizedota/${user.id}`)
    .then(() => {
        alert('Готово')
        location.reload()
    })
    .catch(() => {
        alert('Упс, Что то не так')
    })
    }

}

</script>
<template>
<div class="content">
    <form @submit.prevent="onSubmit">
        <div class="container-form">
            <p>Имя</p>
            <input 
            v-model="IMYA" 
            placeholder="Имя"
            class="form-control"
            type="text">
        </div>
        <div class="container-form">
            <p>Telegram</p>
            <input 
            v-model="Telegram" 
            placeholder="Телеграм"
            class="form-control"
            type="text">
        </div>
        <div class="container-form">
            <p>Когда куплено?</p>
            <input 
            v-model="Buyed" 
            placeholder="11.11.2011"
            class="form-control"
            type="text">
        </div>
        <div class="container-form">
            <p>Кто вы?</p>
            <input 
            v-model="Whoadd" 
            placeholder="Кто вы?"
            class="form-control"
            type="text">
        </div>
        <button 
        type="submit"
        class="btn btn-primary w-100 mt-2 mb-5"
        :disabled="IMYA.length < 4">
            Добавить
        </button>
    </form>
    <button 
    @click="getPrizeUsers"
    class="btn btn-primary w-100">Получить данные</button>
</div>
<div class="content" v-if="Prize">
 <div 
    class="content-users mt-2" v-for="user in Prize" :key="user.id">
       <div>{{ user.id }}</div>
       <div>{{ user.Name }}</div>
       <div>{{ user.Telegram }}</div>
       <div>{{ user.BuyedHack }}</div>
       <div>{{ user.Whoadd }}</div>
       <button 
       @click="DeleteUser(user)"
       class="btn btn-primary mb-3">Удалить</button>
    </div>
</div>
<h4 v-else>Пока данные нет!</h4>
</template>
<style scoped>
input, textarea{
    width: 80%;
    margin-bottom: 5px;
}
.container-form{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-Infomore input{
  width: 100%;
}
.content{
    width: 100%;
    padding: 25px;
}
.content-users{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media(max-width: 420px){
    .content{
        padding: 0;
    }
    .container-form{
        display: block;
    }
    .content-users{
        display: block;
    }
}
</style>