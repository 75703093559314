import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "@/pages/HomeView.vue";
import WhatCanHack from "@/pages/WhatCanHack.vue"
import DotaNews from "@/pages/DotaNews.vue"
import DotaPrices from "@/pages/DotaPrices.vue"
import DotaRegister from "@/pages/DotaRegister.vue"
import DotaNotFound from "@/pages/NotFound.vue"
import CreatePost from "@/pages/CreatePost.vue"
import DotaLogin from "@/pages/DotaLogin.vue"
import Account from "@/components/Account.vue"
import Instruction from "@/components/Instruction.vue"
import Kaelkey from "@/guides/Kaelkey.vue"
import Meepokey from "@/guides/Meepokey.vue"
import DTM from "@/guides/DotaTheme.vue"
import test from "@/pages/Send_minusCount.vue"
import Wallets from "@/components/wallets.vue"
import ActiveKey from "@/components/Active_key.vue"
import Lastkeys from '@/components/LastKey.vue'
import Feedback from '@/components/Feedback.vue'
import GetLogs from '@/components/_Logs.vue'
import AdmGetLogs from '@/components/_AdminLogs.vue'
import ClubKeys from '@/pages/Club_SendMinusCount.vue'
import AdminLogsItem from '@/components/_AdminLogs_Item.vue'
import AdminKeysItem from '@/components/_AdminKeys_item.vue'
import PrizeDota from '@/pages/PrizeUsers.vue'
import ADMPRIZE from '@/components/_AdminPrize_item.vue'

const routes = [
  {
    path: "/",
    component: HomeView,
  },
  {
    path: "/abouthack",
    component: WhatCanHack,
  },
  {
    path: "/news",
    component: DotaNews,
  },
  {
    path: "/prices",
    component: DotaPrices,
  },
  {
    path: "/register",
    component: DotaRegister,
  },
  {
    path: "/login",
    component: DotaLogin,
  },
  {
    path: "/poster",
    component: CreatePost,
  },
  {
    path: "/account",
    component: Account,
  },
  {
    path: "/instructionSecretInformation",
    component: Instruction,
  },
  {
    path: "/kaelkey",
    component: Kaelkey,
  },
  {
    path: "/meepokey",
    component: Meepokey,
  },
  {
    path: "/themeManager",
    component: DTM,
  },
  {
    path: "/tester",
    component: test,
  },
  {
    path: "/wallets",
    component: Wallets,
  },
  {
    path: "/activeKey",
    component: ActiveKey,
  },
  {
    path: "/lastkeys",
    component: Lastkeys,
  },
  {
    path: "/feedback",
    component: Feedback,
  },
  {
    path: "/getlogs",
    component: GetLogs,
  },
  {
    path: "/admingetlogs",
    component: AdmGetLogs,
  },
  {
    path: "/testerClub",
    component: ClubKeys,
  },
  {
    path: "/prize",
    component: PrizeDota,
  },
  {
    path: "/admin/addprize",
    component: ADMPRIZE,
  },
  {
    path: "/admin/:itemAlias",
    component: AdminLogsItem,
  },
  {
    path: "/adminkeys/:itemAlias",
    component: AdminKeysItem,
  },
  {
    path: '/:CatchAll(.*)',
    component: DotaNotFound,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
