<script setup>
import configs from '../configs/config'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { ref, onMounted } from 'vue'

//const loading = ref(true)
const Item = ref('')

const route = useRoute()
const IDFSITE = route.params.itemAlias

onMounted(() => {
    getDetailsLog()
 })

const getDetailsLog = async () =>{
    await axios.get(`${configs.BackJSON}/HackPlayersInfo?IDFSITE=${IDFSITE}`)
    .then(response => {
        Item.value = response.data
    })
    .catch(err => {
        console.log(err)
    })
}
</script>
<template>
<div class="Сontrol-ItemsEx">   
 <div
    class="Control-Items" 
    :key="log.id"
    v-for="log in Item">
    <div class="item"><span>Логин <br>{{ log.USERNAME }}</span></div>
    <div class="item"><span>Почта <br>{{ log.Email }}</span></div>
    <div class="item"><span>Время <br>{{ log.Time }}</span></div>
    <div class="item"><span>IP <br>{{ log.IP }}</span></div>
    <div class="item" v-if="log.AMOUNT || log.AMOUNT >= 0"><span>Последний раз <br><img src="../assets/img/keysAmount.png" width="30">{{ log.AMOUNT }}</span></div>
    <div class="item" v-else><img src="../assets/img/keysAmount.png" width="30"><br><span>Not Found</span></div>
 </div>
</div> 
</template>
<style scoped>
.Сontrol-ItemsEx{
    display: flex;
    flex-wrap: wrap-reverse;
}
.Control-Items{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
.item{
    width: 19.0%;
    border: 1px solid #c9daeb;
    border-radius: 4%;
    background-color: #f8fbfc;
    margin: 8px 8px 0 0;
    padding: 3px;
}
@media(max-width: 420px){
    .Control-Items{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 15px;
    }
    .item{
        width: 95.0%;
        margin: 0;
    }
}
</style>