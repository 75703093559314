<script setup>
import configs from '../configs/config'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { ref, onMounted } from 'vue'

//const loading = ref(true)
const Item = ref('')

const route = useRoute()
const IDFSITE = route.params.itemAlias

onMounted(() => {
    getDetailsLog()
 })

const getDetailsLog = async () =>{
    await axios.get(`${configs.BackJSON}/dota?IDFSITE=${IDFSITE}`)
    .then(response => {
        Item.value = response.data
        console.log(Item.value)
    })
    .catch(err => {
        console.log(err)
    })
}
</script>
<template>
<div class="control-itemsEx">

<div
    class="Control-Items" 
    :key="key.id"
    v-for="key in Item">
    <div class="item"><span>ID <br>{{ key.IDFSITE }}</span></div>
    <div class="item" v-if="key.USERNAME"><span>Логин <br>{{ key.USERNAME }}</span></div>
    <div class="item" v-else><span>Логин <br>Не найдено</span></div>
    <div class="item-key"><span>HWID <br>{{ key.HWID }}</span></div>
    <div class="item-key">
        <textarea
        readonly
        v-model="key.KEY"
        :key="key.id"
        rows="10" 
        cols="50"/><br>
    </div>
    <div class="item" v-if="key.ShowDate"><span>Время <br>{{ key.ShowDate }}</span></div>
    <div class="item" v-else><span>Время <br>Не найдено</span></div>
 </div>
</div>
</template>
<style scoped>
.control-itemsEx{
    display: flex;
    flex-wrap: wrap-reverse;
}
textarea{
    font-size: 8px;
    font-family: 'vue-w25';
    line-height: 0.9;
    border-radius: 1px;
    width: 90%;
    height: auto;
}
span{
    font-size: 10px;
}
.Control-Items{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
}
.item{
    width: 10.0%;
    height: auto;
    border: 1px solid #c9daeb;
    border-radius: 5px;
    background-color: #f8fbfc;
    margin: 8px 8px 0 0;
    padding: 3px;
}
.item-key{
    width: 30%;
    border: 1px solid #c9daeb;
    border-radius: 5px;
    background-color: #f8fbfc;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .1);
}
@media(max-width: 420px){
    .Control-Items{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 15px;
    }
    .item{
        width: 95.0%;
        margin: 0;
    }
    .item-key{
        width: 95%;
        margin-bottom: 15px;
        margin-top: 15px;
    }
}
</style>