<template>
    <div class="container-doter">
        <div class="container-xxl">
           <h3>Отзывы</h3>
           <div class="row">
             <div class="col-md">
              <button @click="toggler = !toggler">
              <img
                 class="img-fluid"
                 src="../assets/img/feedback/otziv1.jpg"
                 alt="Animals"
                 itemprop="image"
              />
           </button>
              </div>
              <div class="col-md">
                 <button @click="toggler = !toggler">
              <img
                 class="img-fluid"
                 src="../assets/img/feedback/otziv2.jpg"
                 alt="Animals"
                 itemprop="image"
              />
           </button>
              </div>
              <div class="col-md">
                 <button @click="toggler = !toggler">
                 <img
                    class="img-fluid"
                    src="../assets/img/feedback/otziv3.jpg"
                    alt="Animals"
                    itemprop="image"
                 />
              </button>
              </div>
              <div class="col-md">
                 <button @click="toggler = !toggler">
                 <img
                    class="img-fluid"
                    src="../assets/img/feedback/otziv4.jpg"
                    alt="Animals"
                    itemprop="image"
                 />
              </button>
              </div>
           </div>
           <!-- вторая - 3штук фото -->
           <div class="row">
              <div class="col-md">
              <button @click="toggler = !toggler">
              <img
                 class="img-fluid"
                 src="../assets/img/feedback/otziv5.jpg"
                 alt="Animals"
                 itemprop="image"
              />
           </button>
              </div>
              <div class="col-md">
                 <button @click="toggler = !toggler">
              <img
                 class="img-fluid"
                 src="../assets/img/feedback/otziv6.jpg"
                 alt="Animals"
                 itemprop="image"
              />
           </button>
              </div>
              <div class="col-md">
                 <button @click="toggler = !toggler">
                 <img
                    class="img-fluid"
                    src="../assets/img/feedback/otziv7.jpg"
                    alt="Animals"
                    itemprop="image"
                 />
              </button>
              </div>
           </div>
           <div class="row">
              <div class="col-md">
              <button @click="toggler = !toggler">
              <img
                 class="img-fluid"
                 src="../assets/img/feedback/otziv8.jpg"
                 alt="Animals"
                 itemprop="image"
              />
           </button>
              </div>
              <div class="col-md">
                 <button @click="toggler = !toggler">
              <img
                 class="img-fluid"
                 src="../assets/img/feedback/otziv9.jpg"
                 alt="Animals"
                 itemprop="image"
              />
           </button>
              </div>
              <div class="col-md">
                 <button @click="toggler = !toggler">
                 <img
                    class="img-fluid"
                    src="../assets/img/feedback/otziv10.jpg"
                    alt="Animals"
                    itemprop="image"
                 />
              </button>
              </div>
           </div>
        </div>
        <FsLightbox
           :toggler="toggler"
           :sources="[
              require('../assets/img/feedback/otziv1.jpg'),
              require('../assets/img/feedback/otziv2.jpg'),
              require('../assets/img/feedback/otziv3.jpg'),
              require('../assets/img/feedback/otziv4.jpg'),
              require('../assets/img/feedback/otziv5.jpg'),
              require('../assets/img/feedback/otziv6.jpg'),
              require('../assets/img/feedback/otziv7.jpg'),
              require('../assets/img/feedback/otziv8.jpg'),
              require('../assets/img/feedback/otziv9.jpg'),
              require('../assets/img/feedback/otziv10.jpg'),
              ]"
        ></FsLightbox>
     </div>
     
</template>
<script scoped>
import FsLightbox from "fslightbox-vue/v3";

export default {
 components: { FsLightbox },
 data() {
   return {
     toggler: false,
   };
 },
};
</script>
<style scoped>
.container-doter{
  padding-top: 60px;
}
h3{
  margin-top: 15px;
  font-family: 'vue-w25';
}
p{
  font-family: 'vue-w25';
}
</style>