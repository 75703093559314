<script setup>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import configs from '@/configs/config';

const Prize = ref('')
const loading = ref(true)

onMounted(() => {
    getDetailsUsers()
 })

 async function getDetailsUsers() {
    try {
    loading.value = true;
    const response = await axios.get(`${configs.BackJSON}/prizedota`)
    Prize.value = response.data
    } catch (err) {
    console.log(err)
    } finally {
        setTimeout(() => {
            loading.value = false;
        }, 500);
    }
}
//PrizeDota - PrizeDotaHistory
</script>
<template>
    <h4>Призы!<br><img src="../assets/img/Prize.png" width="70"></h4>
    <p>Правила: Игроки которые потратили от 1099р попадает в этот список.<br>
        Если вы совершали покупки в Cумах (Uz) , Тенге (KZ), Сомах (KG) или любой другой валюте - вы автоматически участвуете в этой ежемесячной акции!</p>
        <p>Если вы покупали за 1099руб - Приз: 10 дней</p>
        <p>Если вы покупали за 1599руб - Приз: 15 дней</p>
        <p>Если вы покупали за 2699руб - Приз: 25 дней</p>
        <p>Если вы покупали за 4699руб - Приз: 3 - месяц</p>
        <h4>Минимальное кол-во участников: 15.</h4>
        <h4 
            style="text-align: center; font-family: 'vue-w25'; margin-top: 25px;"
            v-if="loading">
                Загрузка...
        </h4>
    <div 
    v-else
    class="container-users" v-for="user in Prize" :key="user.id">
        <div class="block"><span>ID <br>{{ user.id }}</span></div>
        <div class="block"><span>Имя <br>{{ user.Name }}</span></div>
        <div class="block"><span>Telegram <br>{{ user.Telegram }}</span><br></div>
        <div class="block"><span>Купил <br>{{ user.BuyedHack }}</span></div>
        <div class="block"><span>Кто добавил? <br>{{ user.Whoadd }}</span></div>
    </div>
</template>
<style scoped>
.container-users{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.block{
    width: 19%;
    border: 1px solid #c9daeb;
    border-radius: 5px;
    background-color: #f8fbfc;
    padding: 8px;
    margin: 5px;
}
h4{
    font-family: 'vue-w25';
}
@media(max-width: 420px){
    .container-users{
        margin-bottom: 35px;
    }
    .block{
        width: 97%;
        margin: 0;
    }
}
</style>